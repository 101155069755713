<template>
  <div class="skeleton-box text-red-lp-1600 text-orange-lp-300" :style="skeletonStyles"></div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
@Options({
  name: "Skeleton",
  props: {
    height: {
      type: String,
      default: "1rem"
    },
    width: {
      type: String,
      default: "100%"
    }
  },
  computed: {
    skeletonStyles() {
      return {
        height: this.height,
        width: this.width,
        borderRadius: "0.25rem"
      };
    }
  }
})
export default class Skeleton extends Vue {}
</script>

<style scoped>
.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: #ebecf0;
}
.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 2s infinite;
  content: "";
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
</style>
