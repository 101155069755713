
import { Options, Vue, prop } from "vue-class-component";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import { PropType } from "vue";

class Props {
  onBack = prop<Function>({
    type: Function,
    required: false,
    default: Function as PropType<() => void>
  });
  isBack = prop<boolean>({
    type: Boolean,
    default: true
  });
  fullPage = prop<boolean>({
    default: false,
    type: Boolean
  });
}

@Options({
  components: {
    Skeleton
  }
})
export default class PageDetailLazyLoad extends Vue.with(Props) {}
